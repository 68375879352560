import { SortDirection } from '../ports'
import { PageInfo } from '../ports/Page'
import { Message } from './Message'
import { Collection } from './Collection'
import { Reminder } from './Reminder'
import {
  Audience,
  BookingIntent,
} from '../../secondaryAdapters/Repository/DB/Enquiry'

export const MAX_ENQUIRY_RESPONSES = 15

export enum PostalCode {
  Ec = 'EC',
  Wc = 'WC',
  E = 'E',
  Se = 'SE',
  Sw = 'SW',
  W = 'W',
  Nw = 'NW',
  N = 'N',
}

export enum Layout {
  U_SHAPED = 'u-shaped',
  SEATING = 'seating',
  STANDING = 'standing',
  BOARDROOM = 'boardroom',
  CLASSROOM = 'classroom',
  THEATRE = 'theatre',
  CABARET = 'cabaret',
}

export enum EnquiryStatus {
  Active = 'active',
  Closed = 'closed',
  Duplicated = 'duplicated',
  Unqualifiable = 'unqualifiable',
  Takenbyams = 'takenbyams',
  Old = 'old',
  Private = 'private',
}

export enum EnquirySortField {
  Id = 'id',
  CreateDate = 'createDate',
  EventType = 'eventType',
  Budget = 'budget',
  EventDate = 'eventDate',
  GuestsCount = 'guestsCount',
  RelevancyFit = 'relevancyFit',
  HostVisibilityDate = 'hostVisibilityDate',
}

export enum EnquirySource {
  HeadBox = 'HeadBox',
  BriefBuilder = 'BriefBuilder',
}

export interface EnquirySort {
  field: EnquirySortField
  direction: SortDirection
}

export enum EnquiryShowOption {
  STARRED = 'STARRED',
  UNREAD = 'UNREAD',
  GOOD_FIT = 'GOOD_FIT',
}

export interface VenueCoordinates {
  lonlat?: string
  id: number
  active: boolean
}

export enum EnquiryClosedSource {
  Modal = 'modal',
  EnquiryPage = 'enquiry_page',
  Admin = 'admin',
}

export enum EnquiryContactPreference {
  Phone = 'PHONE',
  Email = 'EMAIL',
  Text = 'TEXT',
}

export interface EnquiryFilters {
  ids?: number[]
  minCapacity?: number
  maxCapacity?: number
  minGuestsCount?: number
  maxGuestsCount?: number
  minBudget?: number
  maxBudget?: number
  postalCodes?: PostalCode[]
  eventTypes?: [string]
  fromDate?: string
  toDate?: string
  fromEventDate?: string
  maxAgeInDays?: number
  toEventDate?: string
  statuses?: EnquiryStatus[]
  excludeStatuses?: EnquiryStatus[]
  excludeHostIds?: number[]
  excludePoorFits?: boolean
  nullCollectionId?: boolean
  collectionIds?: number[]
  nullEventType?: boolean
  guestId?: number
  guestIds?: number[]
  regions?: string[]
  venuesCoordinates?: VenueCoordinates[]
  filterSetId?: number
  hostId?: number
  excludeNotInterested?: boolean
  excludeMessaged?: boolean
  excludeClosed?: boolean
  showOnly?: EnquiryShowOption[]
  excludePriorityBlocked?: boolean
  maxNotificationBatchesSent?: number
  hasUnsentNotifications?: boolean
}

export interface Enquiry {
  id: number
  uuid: string
  readWriteAccessSecret: string
  title: string
  status: EnquiryStatus
  eventDateFrom: string
  eventDateTo: string
  timeSlots: string
  eventMacroType?: string // from HeadBox
  eventType?: string // from HeadBox
  audience: Audience // from Brief Builder
  bookingIntent: BookingIntent // from Brief Builder
  layout: string
  postalCode?: string // from HeadBox
  publicPostalCode?: string
  budget: number
  budgetNotes?: string | null
  currency: string
  createDate: string
  timings?: string
  capacity?: number // from HeadBox
  notes?: string
  guestsCount: number
  guestId: number
  // guestEmail: string
  hostId?: number // from HeadBox
  venueId?: number // from HeadBox
  venueName?: string // from HeadBox
  venueDescription?: string // from HeadBox
  spaceName?: string // from HeadBox
  venueLatitude?: number // from HeadBox
  venueLongitude?: number // from HeadBox
  source: EnquirySource
  briefId?: number // from Brief Builder
  collectionId?: number // from Brief Builder
  location: string // from Brief Builder
  latitude: number // from Brief Builder
  longitude: number // from Brief Builder
  legacyFlexibleDatesOrTimes?: boolean // originally from Brief Builder
  flexibleDates?: boolean // from Brief Builder
  flexibleTimes?: boolean // from Brief Builder
  flexibleDatesNote?: string // from Brief Builder
  cateringNeeds?: string // from Brief Builder
  spaceStyleIds: string[] // from Brief Builder
  optionalFacilityIds: number[] // from Brief Builder
  requiredFacilityIds: number[] // from Brief Builder
  messages?: Message[]
  spaceId?: number // from Brief Builder
  country: string // from Brief Builder
  radius: number // from Brief Builder
  reminders?: Reminder[]
  guestEnquiryNotificationFailedAt?: string | null
  closedSource?: EnquiryClosedSource | null
  preferredContactMethods: string[]
  hasPriorityVisibilityEnabled: boolean
  guestNotificationBatchIndex: number
  allHostNotificationsSentAt?: Date
  showPricingAndAvailability: boolean
  messageLimit: number
  priorityAccessMinutes: number
}

export interface EnquiryForEmail extends Enquiry {
  collection: Collection | null
  goodFitNotification?: boolean
}

export interface EnquiriesPage {
  rows: Enquiry[]
  totalRows: number
  page: PageInfo
  totalPages: number
}
